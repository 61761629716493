import React, { useContext, useEffect ,useState} from "react";
import { Container, Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { MyContext } from "../config/config";
import ReactLanguageSelect from 'react-languages-select';
import 'react-languages-select/css/react-languages-select.css';
import { useTranslation } from 'react-i18next';
import { apiService } from "../service/api.service";
import i18n from 'i18next';
import '../i18n';

const Header = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {editProfilr, setEditProfile}=useContext(MyContext)
  const selectedlanguage = localStorage.getItem("lang")
  const [totalUsers, setTotalUsers] = useState(0)
  const page = 0;
  const size = 1;
  const status = "KYC_VIDEO_UPLOADED";

  const changeLanguage = (lng) => {
    localStorage.setItem("lang",lng)
    languageChange(lng)
    i18n.changeLanguage(lng);
  };

  const languageChange = (lng)=>{
    apiService.changeLanguage(lng).then((res) => {
     window.scrollTo({ top: 0, behavior: "smooth" });
    
    }).catch((err) => { 
    });
  }

  useEffect(() => {
      getPendingKycUser()
  }, [])

  const getPendingKycUser = async () => {
    try {
      const response = await apiService.get_usersDash(page, size, status);
      if(response.status === 200){
        setTotalUsers(response.data.data.total);
      }
    } catch (error) {
      handleErrors(error);
    } 
  };

  const handleErrors = (error) =>{
    if (error?.response?.status == 401) {
        swal({ icon: "error", text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear();
          window.location.href = "/";
          console.log("OK button clicked after error alert");
        });
      } else {
        if (error?.response?.status == 403) {
          swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
              (value) => {
                  localStorage.clear()
                  window.location.href = "/";
              }
          );
      }else{
          swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
              (value) => {
                  console.log('OK button clicked after error alert');
              }
          );
      }
    }
  }

  
  return (
    <>
      <div className="admin-header">
        <Container fluid>
          {totalUsers > 0 && (
            <div className="error-msg">
              <p>{t("kyc_pending_text", { count: totalUsers })}
              <a href="/Kyc-management" > {t("click_here")}</a></p>
            </div>
          )}

          <div className="header-right">
            <div className="logo-mob">
             <img src={require("../assets/images/logo-mob.png")} alt="img" />
            </div>
            <ReactLanguageSelect onSelect ={(e)=>changeLanguage(e)} defaultLanguage={selectedlanguage} languages={["en", "es"]} customLabels={{"en": "English", "es": "Spanish"}} placeholder="Change Language"  className="select-language" />
            <div className="logout-btn">
           
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  <img src={require("../assets/images/user.png")} alt="" />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                <Dropdown.Item
                    onClick={()=>{navigate("/ChangePassword")}}
                  >
                    <i className="fa fa-lock" aria-hidden="true"></i>
                    {t("changepass")}
                  </Dropdown.Item>
                  <Dropdown.Item
                   onClick={()=>{
                    swal({
                      title: t("are_you_sure"),
                      text: t('logout_confirm'),
                      icon: 'warning',
                      buttons: [t('cancel'), t('logout_yes')],
                      dangerMode: true,
                    }).then((confirmed) => {
                      if (confirmed) {

                        localStorage.clear();
                        localStorage.setItem("lang",selectedlanguage)
                        navigate("/");
                        
                      }
                    })
                   }}
                    
                  >
                    <i className="fa fa-sign-out" aria-hidden="true"></i>
                  {t("logout")}
                  </Dropdown.Item>
                  
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
         
        </Container>
      </div>
    </>
  );
};
export default Header;
