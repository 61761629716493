import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useContext } from "react";
import { Collapse } from "react-bootstrap";
import { MyContext } from "../config/config";
import { useTranslation } from 'react-i18next';
import '../i18n';
const Sidebar = ({ isOpen, setIsOpen }) => {
  const { setOpenLoad } = useContext(MyContext);
  const [sidebarOpenMobile, setSidebarOpenMobile] = useState(false);
  if (window.location.pathname != "/users") {
    localStorage.setItem("previousPath", window.location.pathname);
  }

  const location = useLocation();
  const { t } = useTranslation();
  useEffect(() => {
    if (location.pathname == "/users") {
    }
    if (location.pathname.split("/")[1] == "PrimaryClient") {
      localStorage.setItem("tab", "primary");
    } else if (location.pathname.split("/")[1] == "UserList") {
      localStorage.setItem("tab", "users");
    } else if (
      location.pathname.split("/")[1] !== "userInfo" &&
      location.pathname.split("/")[1] !== "PrimaryClient" &&
      location.pathname.split("/")[1] !== "UserList"
    ) {
      localStorage.removeItem("tab");
    }
    if (location.pathname.split("/")[1] == "LoadManagement") {
      setOpenLoad(true);
    } else {
      setOpenLoad(false);
    }
  }, [location.pathname]);

  return (
    <>
      <div className="sidebar-overlay">
        <div
          onClick={() => setSidebarOpenMobile(!sidebarOpenMobile)}
          className={`${sidebarOpenMobile ? "sidebar-cross-btn" : "sidebar-toggle-btn"
            } `}
        >
          <img
            src={
              sidebarOpenMobile
                ? require("../assets/images/sidebar-cross.svg").default
                : require("../assets/images/sidebar-menu.svg").default
            }
            alt="img"
          />
        </div>

        <section className="sidebar">
          <div className="logo">
            <img src={require("../assets/images/front-logo.png")} alt="" />
          </div>
          <div className="">
            <Link
              to="/Home"
              className={
                location.pathname == "/Home"
                  ? "sidebar_option active"
                  : "sidebar_option"
              }
            >
              <img
                src={require("../assets/images/dashboard.svg").default}
                alt=""
              />
              {t("dashbord")}
            </Link>
            <Link
              to="/users"
              onClick={() => localStorage.setItem("tab", "users")}
              className={
                location.pathname == "/users" ||
                  location.pathname.split("/")[1] == "user-details" ||
                  location.pathname.split("/")[1] == "cultivator-details" ||
                  location.pathname.split("/")[1] == "driver-details"
                  ? "sidebar_option active"
                  : "sidebar_option"
              }
            >
              <img src={require("../assets/images/audit.svg").default} alt="" />
              {t("user_managemnet")}
            </Link>
            <Link
              to="/categories"
              // to="#"
              className={
                location.pathname == "/categories"
                  ? "sidebar_option active"
                  : "sidebar_option"
              }
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-category-filled me-2" width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M10 3h-6a1 1 0 0 0 -1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1 -1v-6a1 1 0 0 0 -1 -1z" stroke-width="0" fill="currentColor" />
                <path d="M20 3h-6a1 1 0 0 0 -1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1 -1v-6a1 1 0 0 0 -1 -1z" stroke-width="0" fill="currentColor" />
                <path d="M10 13h-6a1 1 0 0 0 -1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1 -1v-6a1 1 0 0 0 -1 -1z" stroke-width="0" fill="currentColor" />
                <path d="M17 13a4 4 0 1 1 -3.995 4.2l-.005 -.2l.005 -.2a4 4 0 0 1 3.995 -3.8z" stroke-width="0" fill="currentColor" />
              </svg>
              {t("category_management")}
            </Link>
            <Link
              to="/units"
              // to="#"
              className={
                location.pathname == "/units"
                  ? "sidebar_option active"
                  : "sidebar_option"
              }
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-usb me-2" width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="currentColor" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M12 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                <path d="M12 17v-11.5" />
                <path d="M7 10v3l5 3" />
                <path d="M12 14.5l5 -2v-2.5" />
                <path d="M16 10h2v-2h-2z" />
                <path d="M7 9m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
                <path d="M10 5.5h4l-2 -2.5z" />
              </svg>
              {t("unit_management")}

            </Link>

            <Link
              to="/products"
              className={
                location.pathname == "/products" || location.pathname.split("/")[1] == "product-detail"
                  ? "sidebar_option active"
                  : "sidebar_option"
              }
            >
              <img
                src={require("../assets/images/dashboard.svg").default}
                alt=""
              />
              {t("product_management")}

            </Link>
            <Link
              to="/order-mangement/orders"
              onClick={() => localStorage.setItem("tab", "users")}
              className={
                location.pathname == "/order-mangement/orders" || location.pathname.split("/")[2] =="order-details"
                  ? "sidebar_option active"
                  : "sidebar_option"
              }
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-shopping-cart-filled me-2" width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M6 2a1 1 0 0 1 .993 .883l.007 .117v1.068l13.071 .935a1 1 0 0 1 .929 1.024l-.01 .114l-1 7a1 1 0 0 1 -.877 .853l-.113 .006h-12v2h10a3 3 0 1 1 -2.995 3.176l-.005 -.176l.005 -.176c.017 -.288 .074 -.564 .166 -.824h-5.342a3 3 0 1 1 -5.824 1.176l-.005 -.176l.005 -.176a3.002 3.002 0 0 1 1.995 -2.654v-12.17h-1a1 1 0 0 1 -.993 -.883l-.007 -.117a1 1 0 0 1 .883 -.993l.117 -.007h2zm0 16a1 1 0 1 0 0 2a1 1 0 0 0 0 -2zm11 0a1 1 0 1 0 0 2a1 1 0 0 0 0 -2z" stroke-width="0" fill="currentColor" />
              </svg>
              
              {t("order_management")}

              
            </Link>
            <Link
              to="/transaction"
              className={
                location.pathname == "/transaction" || location.pathname.split("/")[1] == "transaction-management"
                  ? "sidebar_option active"
                  : "sidebar_option"
              }
            >
              <img
                src={require("../assets/images/transaction-new.svg").default}
                alt=""
              />
              {t("transaction_management")}

            </Link>
            
            <Link
              to="#"
              onClick={() => {
                window.location.href = "/kyc-management"; 
               
              }}
              className={
                location.pathname == "/kyc-management"
                  ? "sidebar_option active"
                  : "sidebar_option"
              }
            >
              <img
                src={require("../assets/images/kvc-management.svg").default}
                alt=""
              />
              {t("Kyc_management")}
            </Link>

            {/* <Link
              to="/payout"
              className={
                location.pathname == "/payout"
                  ? "sidebar_option active"
                  : "sidebar_option"
              }
            >
              <img
                src={require("../assets/images/transaction-new.svg").default}
                alt=""
              />
              {t("payout_management")}

            </Link> */}


 {/*
             
            <Link
              to="/translation"
              className={
                location.pathname == "/translation"
                  ? "sidebar_option active"
                  : "sidebar_option"
              }
            >
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-language me-2" width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="currentColor" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M4 5h7" />
                <path d="M9 3v2c0 4.418 -2.239 8 -5 8" />
                <path d="M5 9c0 2.144 2.952 3.908 6.7 4" />
                <path d="M12 20l4 -9l4 9" />
                <path d="M19.1 18h-6.2" />
              </svg>
              {t("Translation Management")}

            </Link> */}

            <Link
              to="/contact-us"
              //  to="#"
              className={
                location.pathname == "/contact-us" ||
                  location.pathname.split("/")[1] == "contact-us"
                  ? "sidebar_option active"
                  : "sidebar_option"
              }
            >
              <img
                src={require("../assets/images/contact-us.svg").default}
                alt=""
              />
              {t("contact_us")}

            </Link>
            <Link
              to="/settings"
              //  to="#"
              className={
                location.pathname == "/settings" ||
                  location.pathname.split("/")[1] == "settings"
                  ? "sidebar_option active"
                  : "sidebar_option"
              }
            >
              <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-settings-filled me-2" width="24" height="24" viewBox="0 0 24 24" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M14.647 4.081a.724 .724 0 0 0 1.08 .448c2.439 -1.485 5.23 1.305 3.745 3.744a.724 .724 0 0 0 .447 1.08c2.775 .673 2.775 4.62 0 5.294a.724 .724 0 0 0 -.448 1.08c1.485 2.439 -1.305 5.23 -3.744 3.745a.724 .724 0 0 0 -1.08 .447c-.673 2.775 -4.62 2.775 -5.294 0a.724 .724 0 0 0 -1.08 -.448c-2.439 1.485 -5.23 -1.305 -3.745 -3.744a.724 .724 0 0 0 -.447 -1.08c-2.775 -.673 -2.775 -4.62 0 -5.294a.724 .724 0 0 0 .448 -1.08c-1.485 -2.439 1.305 -5.23 3.744 -3.745a.722 .722 0 0 0 1.08 -.447c.673 -2.775 4.62 -2.775 5.294 0zm-2.647 4.919a3 3 0 1 0 0 6a3 3 0 0 0 0 -6z" stroke-width="0" fill="currentColor" />
              </svg>
              {t("settings")}

            </Link>
          </div>
        </section>

        {sidebarOpenMobile && (
          <section className="sidebar sidebar-mobile">
            <div className="logo">
              <img src={require("../assets/images/front-logo.png")} alt="" />
            </div>
            <div className="">
              <Link
                to="/Home"
                className={
                  location.pathname == "/Home"
                    ? "sidebar_option active"
                    : "sidebar_option"
                }
              >
                <img
                  src={require("../assets/images/dashboard.svg").default}
                  alt=""
                />
                {t("dashboard")}
              </Link>

              <Link
                to="/users"
                onClick={() => localStorage.setItem("tab", "users")}
                className={
                  (localStorage.getItem("tab") == "users" &&
                    location.pathname == "/UserList") ||
                    (localStorage.getItem("tab") == "users" &&
                      location.pathname.split("/")[1] == "userInfo") ||
                    location.pathname == "/UserList"
                    ? "sidebar_option active"
                    : "sidebar_option"
                }
              >
                <img
                  src={require("../assets/images/audit.svg").default}
                  alt=""
                />
                {t("user_managemnet")}
              </Link>
              {/* <Link
                // to="/predictions"
                to="#"
                className={
                  location.pathname == "/predictions" ||
                  location.pathname.split("/")[1] == "prediction-detail"
                    ? "sidebar_option active"
                    : "sidebar_option"
                }
              >
                <img
                  src={require("../assets/images/dashboard.svg").default}
                  alt=""
                />
                Prediction Management
              </Link> */}
            </div>
          </section>
        )}
      </div>
    </>
  );
};
export default Sidebar;
