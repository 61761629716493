import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Container,Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import "../../i18n";
import { apiService } from "../../service/api.service";
import swal from "sweetalert";
import { imageUrl } from "../../config/config";
import InfiniteScroll from "react-infinite-scroll-component";
import { ThreeDotSpinner } from "../loader";
import { Link } from "react-router-dom";

export const KYC = () => {
  const { t } = useTranslation();
  const [previewImage, setpreviewImage] = useState();
  const [show_img_info, setShow_img_info] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [pendingKycUsers, setPendingKycUsers] = useState([]);
  const [userId ,setUserId] = useState();
  const [show, setShow] = useState(false);
  const [kycRejectReason, setKycRejectReason] = useState("")
  const [reasonError, setReasonError] = useState("")
  const [loader, setLoader] = useState(false);
  
  const [page, setPage] = useState(0);
  const size = 4;  
  const status = "KYC_VIDEO_UPLOADED";


  useEffect(() => {
      getPendingKycUsers();
  }, []);

  const getPendingKycUsers = async () => {
    try {
      const response = await apiService.get_usersDash(page, size, status);
      if(response.status == 200){
        const newUsers = response.data.data.list;

        setPendingKycUsers((prevUsers) => {
          const uniqueUsers = [...new Map([...prevUsers, ...newUsers].map(user => [user.id, user])).values()];
          return uniqueUsers;
        });  
        if (
          newUsers.length < size ||
          response.data.data.total <= (page + 1) * size
        ) {
          setHasMore(false);
        } else {
          setPage((prevPage) => prevPage + 1);
        }
      }
     
    } catch (error) {
      handleErrors(error);
    }
  };

  function disableUser() {
    if (kycRejectReason.trim() !== "") {
      accountVerification("KYC_VIDEO_REJECTED", userId)
      setReasonError("")
      setShow(false)
    } else {
      setReasonError(t("enter_reason"))

    }
  }

  const accountVerification = async (status, id) => {
    setLoader(true);

    try {
      const response = await apiService.acceptReject(id, status,kycRejectReason);
      if (response?.status == 200 || response?.status == 201) {
        setLoader(false);
        if (status == "Accept") {
          swal(t("success"), t("profile_accepted"), "success").then(() => {
            window.location.reload();
          });
        } else {
          swal(t("success"), t("profile_rejected"), "success").then(() => {
            window.location.reload();
          });
        }
      }
    } catch (error) {
      handleErrors(error);
    }
  };

  const handleErrors = (error) => {
    setLoader(false);
    if (error?.response?.status == 401) {
      swal({ icon: "error", text: "Unauthorized", button: "OK" }).then(() => {
        localStorage.clear();
        window.location.href = "/";
        console.log("OK button clicked after error alert");
      });
    } else {
      if (error?.response?.status == 403) {
        swal({
          icon: "error",
          text: error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message,
          button: "OK",
        }).then((value) => {
          localStorage.clear();
          window.location.href = "/";
        });
      } else {
        swal({
          icon: "error",
          text: error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message,
          button: "OK",
        }).then((value) => {
          console.log("OK button clicked after error alert");
        });
      }
    }
  };

  const handleShow_img_info = (img) => {
    console.log(img);
    setpreviewImage(img);
    setShow_img_info(true);
  };

  const handleClose_img_info = () => {
    setpreviewImage();
    setShow_img_info(false);
  };

  const handleShow = (id) => {
    setUserId(id);
    setShow(true);
  }

  const handleClose = () => { 
    setShow(false); 
  };   

  return (
    <>
      <div className="dashboard-main-area">
        {loader == true ? <ThreeDotSpinner /> : ""} 
        <Container fluid>
          <div className="dashboard-area-heading management-heading">
            <Row className="justify-content-center align-items-center mb-3">
              <Col md={12} lg={11}>
                <div className="transaction-management-top">
                  <div className="heading-top-area">
                    <div className="d-flex ">
                      <h2 className="align-self-center m-0">{t("Kyc_management")}</h2>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <InfiniteScroll
              dataLength={pendingKycUsers.length}
              next={getPendingKycUsers}
              hasMore={hasMore}
              scrollableTarget="scrollableDiv"
            >
              <Row className="pb-4 justify-content-center">
                <Col md={12} lg={11}>
                  <Row xs={1} md={2}>
                    {pendingKycUsers.map((user, index) => (
                      <Col key={index}>
                        <Card className="p-3 card-information-view mb-2 rounded-2">
                          <Row className="">
                            {/* Left side (Single Image) */}
                            <Col md={4}>
                              <video className="kyc-card-video" controls muted>
                                <source
                                  src={imageUrl + user?.driver?.kycVideo}
                                  type="video/mp4" 
                                />
                                Your browser does not support the video tag.
                              </video>
                            </Col>

                            {/* Right side (Two stacked images) */}
                            <Col
                              md={8}
                              className="uploaded-files p-0 kyc-upload-img-area "
                            >
                              <div className="d-flex gap-4">
                                <div>
                                  <Card.Img
                                    className="kyc-card-image p-0"
                                    variant="top"
                                    src={
                                      imageUrl + user?.driver?.drivingLicence
                                    }
                                  />

                                  <a
                                    href="#"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handleShow_img_info(
                                        imageUrl + user?.driver?.drivingLicence
                                      );
                                    }}
                                  >
                                    <p>{t("preview_driving_image")}</p>
                                  </a>
                                </div>
                                <div>
                                  <Card.Img
                                    className="kyc-card-image p-0"
                                    variant="top"
                                    src={imageUrl + user?.driver?.licenceBack}
                                  />

                                  <a
                                    href="#"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handleShow_img_info(
                                        imageUrl + user?.driver?.licenceBack
                                      );
                                    }}
                                  >
                                    <p>{t("preview_back")}</p>
                                  </a>
                                </div>
                              </div>
                              <Card.Body className="px-2 card-content m-0">
                                <Card.Title>
                                  {user.firstName} {user.lastName}
                                </Card.Title>
                                <Card.Text>
                                  <Link 
                                  to="#"
                                  onClick={() => {
                                    window.location.href = "/driver-details/" + user.id; 
                                   
                                  }}
                                  ><p>See Details</p></Link>                               
                                </Card.Text>
                              </Card.Body>
                              <div className="accept-reject-kyc">
                                <Button
                                  className="accept-btn"
                                  onClick={() => {
                                    swal({
                                      title: t("are_you_sure"),
                                      text: t("accept_want"),
                                      icon: "warning",
                                      buttons: [t("cancel"), t("yes_accept")],
                                      dangerMode: true,
                                    }).then((confirmed) => {
                                      if (confirmed) {
                                        accountVerification("Accept", user.id);
                                      }
                                    });
                                  }}
                                >
                                  <i class="fa-solid fa-check"></i>{" "}
                                  {t("accept")}
                                </Button>

                                <Button
                                  className="accept-btn reject-btn ms-2"
                                  onClick={() => {
                                    swal({
                                      title: t("are_you_sure"),
                                      text: t("reject_want"),
                                      icon: "warning",
                                      buttons: [t("cancel"), t("yes_reject")],
                                      dangerMode: true,
                                    }).then((confirmed) => {
                                      if (confirmed) {
                                        handleShow(user.id)

                                        // accountVerification(
                                        //   "KYC_VIDEO_REJECTED",
                                        //   user.id
                                        // );
                                      }
                                    });
                                  }}
                                >
                                  <i class="fa-solid fa-close text-light"></i>{" "}
                                  {t("reject")}
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
            </InfiniteScroll>
            {pendingKycUsers.length == 0 && (
              <div className="text-center">
                <div className="no-data-found-section">
                  <img
                    src={require("../../assets/images/no-data.svg").default}
                    alt=""
                  />
                  <h5>
                    <b>{t("no_data_found")}</b>
                  </h5>
                </div>
              </div>
            )}
          </div>
        </Container>
      </div>

      <Modal
        show={previewImage}
        onHide={handleClose_img_info}
        className="preview-image-popup">
        <Modal.Body>
          <Modal.Header closeButton>
          </Modal.Header>
          <div className="bank-information-box">
            <Row>
              <Col md={12}>
                <div className="vehicle-img-box-main ">
                  {show_img_info ? (
                    <img
                      src={previewImage}  
                      alt="image"
                      className="vehicle-img-box"
                    />
                  ) : (
                    "Loading..."
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{t("rejection_reason")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <textarea maxLength={500} onChange={(e) => setKycRejectReason(e.target.value)} className="form-control">

          </textarea>
          <span className="text-danger">{reasonError}</span>


        </Modal.Body>
        <Modal.Footer className="pt-3">
          <Button variant="secondary" onClick={handleClose} className="close-btn">
          {t("close")}
          </Button>
          <Button variant="primary" onClick={disableUser}>
          {t("submit")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
