import React, { useState, useEffect } from "react";
import { Button, Col, Row, Table, Form, OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import { InlineLoader } from "../../components/loader";
import { useNavigate, Link } from "react-router-dom";
import swal from "sweetalert";
import { ThreeDotSpinner } from "../../components/loader";
import 'react-toastify/dist/ReactToastify.css';
import { apiService } from "../../service/api.service";
import { MyContext, formatToColombianCurrency } from "../../config/config";
import { useContext } from "react";
import { Chart } from 'react-charts'
import { useTranslation } from 'react-i18next';
import '../../i18n';
export const Home = () => {
  const { filterType, setFilterType, openLoad, setOpenLoad } = useContext(MyContext)
  const { t } = useTranslation();
  const [loader, setLoader] = useState(false);
  const [dashbordData, setDashbordData] = useState([]);
  const [userData, setUserData] = useState([]);
  const tooltip = (
    <Tooltip id="tooltip">
      {t("view")}
    </Tooltip>
  );
  const data = React.useMemo(
    () => [
      {
        label: 'Series 1',
        data: [[0, 1], [1, 2], [2, 4], [3, 2], [4, 7]]
      },
      {
        label: 'Series 2',
        data: [[0, 3], [1, 1], [2, 5], [3, 6], [4, 4]]
      }
    ],
    []
  )

  const axes = React.useMemo(
    () => [
      { primary: true, type: 'linear', position: 'bottom' },
      { type: 'linear', position: 'left' }
    ],
    []
  )

  useEffect(() => {
     dashboardList()
     get_usersDashboard()
  }, [])
  async function get_usersDashboard() {
    setLoader(true)
    try {

      const response = await apiService.get_usersDash(0, 10, "Pending")
      if (response?.status == 200) {
        localStorage.setItem("previousPath", "/Home")
        let responseData = response.data.data.list
        setUserData(responseData)
       

        setLoader(false)
      }
    } catch (error) {
      if (error?.response?.status == 401) {
        setLoader(false)

        if (error?.response?.data?.token) {
          localStorage.setItem("admin_token", error?.response?.data?.token);
          // window.location.reload()
        } else {
          swal({ icon: 'error', text: t("unauth"), button: "OK" }).then(() => {
            localStorage.clear()
            window.location.href = "/";
            console.log('OK button clicked after error alert');
          });
        }
      } else {
        if (error?.response?.status == 403) {
          setLoader(false)
          swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
              (value) => {
                  localStorage.clear()
                  window.location.href = "/";
              }
          );
      }else{
          setLoader(false)
          swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
              (value) => {
             
              }
          );
      }
      }
    }

  }
  
  async function dashboardList() {

    setLoader(true)
    try {
      const response = await apiService.Dashboard()
      if (response?.status == 200) {
        console.log("?????????????dashbord", response?.data?.data)
        setDashbordData(response?.data.data.count)

        setLoader(false)
      }
    } catch (error) {
      if (error?.response?.status == 401) {
        setLoader(false)
         console.log(error?.response?.data?.token)
        if(error?.response?.data?.token){
          localStorage.clear()
          //  localStorage.setItem("admin_token", error?.response?.data?.token);
          // window.location.reload()
        }else{
          swal({ icon: 'error', text: t("unauth"), button: "OK" }).then(() => {
            localStorage.clear()
            window.location.href = "/";
            console.log('OK button clicked after error alert');
          });
        }
      } else {
        setLoader(false)

        swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" }).then(
          (value) => {
            console.log('OK button clicked after error alert');
          }
        );
      }
    }

  }




  return (
    <>
      <div className="dashboard-main-area">
        <div className="dashboard-area main-home-top-area">
          <Row className="justify-content-center">
            <Col md={12} lg={11} className="p-0">
              <Row className="justify-content-center m-2">
                {loader == true ? <ThreeDotSpinner /> : ""}
                <Col md={6} xl={4} className="mb-3">
                  <div className="dashboard-cards">
                    <div className="card-img-view">
                      <img
                        src={require("../../assets/images/total-user.svg").default}
                      />
                    </div>
                    <Link to="/users" className="dashboardlink" onClick={() => setFilterType("trucker")}>
                      <div className="card-content">
                        <h3>{t("total_culti")}</h3>
                        <h2>
                          {" "}
                          {loader ?
                            <InlineLoader />
                            :
                            <>
                              {dashbordData ? <>{dashbordData?.cultivator}</> : <>-</>}
                            </>
                          }
                        </h2>

                      </div>
                    </Link>
                  </div>
                </Col>
                <Col md={6} xl={4} className="mb-3">
                  <div className="dashboard-cards">
                    <div className="card-img-view">
                      <img
                        src={require("../../assets/images/total-user.svg").default}
                      />
                    </div>
                    <Link to="/" className="dashboardlink" onClick={() => setOpenLoad(true)}>
                      <div className="card-content">
                        <h3> {t("total_driver")}</h3>
                        <h2>
                          {" "}
                          {loader ?
                            <InlineLoader />
                            :
                            <>
                            
                              {dashbordData ? <>{dashbordData?.driver}</> : <>-</>}

                            </>
                          }
                        </h2>
                      </div>
                    </Link>
                  </div>
                </Col>

                <Col md={6} xl={4} className="mb-3">
                  <div className="dashboard-cards">
                    <div className="card-img-view">
                      <img
                        src={require("../../assets/images/total-user.svg").default}
                      />
                    </div>
                    <div className="card-content">
                      <h3> {t("total_customer")}</h3>
                      <h2>
                        {" "}
                        {loader ?
                          <InlineLoader />
                          :
                          <>
                              {dashbordData ? <>{dashbordData?.customer}</> : <>-</>}


                          </>
                        }
                      </h2>

                    </div>
                  </div>
                </Col>
                <Col md={6} xl={4} className="mb-3">
                  <div className="dashboard-cards">
                    <div className="card-img-view">
                      <img
                        src={require("../../assets/images/comission.svg").default}
                      />
                    </div>
                    <div className="card-content">
                      <h3>{t("total_products")}</h3>
                      <h2>
                        {" "}
                        {loader ?
                          <InlineLoader />
                          :
                          <>
                              {dashbordData ? <>{dashbordData?.products}</> : <>-</>}


                          </>
                        }
                      </h2>

                    </div>
                  </div>
                </Col>
                <Col md={6} xl={4} className="mb-3">
                  <div className="dashboard-cards">
                    <div className="card-img-view">
                      <img
                        src={require("../../assets/images/comission.svg").default}
                      />
                    </div>
                    <div className="card-content">
                      <h3>{t("total_orders")}</h3>
                      <h2>
                        {" "}
                        {loader ?
                          <InlineLoader />
                          :
                          <>
                              {dashbordData ? <>{dashbordData?.orders}</> : <>-</>}


                          </>
                        }
                      </h2>

                    </div>
                  </div>
                </Col>
                <Col md={6} xl={4} className="mb-3">
                  <div className="dashboard-cards">
                    <div className="card-img-view">
                      <img
                        src={require("../../assets/images/comission.svg").default}
                      />
                    </div>
                    <div className="card-content">
                      <h3>{t("total_earnings")}</h3>
                      <h2>
                        {" "}
                        {loader ?
                                
                          <InlineLoader />
                          :
                          <>
                             {dashbordData ? <> {formatToColombianCurrency(dashbordData?.sales ?? 0)}</> : <>0</>}
                          </>
                        }
                      </h2>

                    </div>
                  </div>
                </Col>
              </Row>
              {/* graph start here */}
              {/* <Row>
                <Col md={6}>
                  <div className="graph-boxes">
                    <div
                      style={{
                        width: '100%',
                        height: '300px'
                      }}
                    >
                      <Chart data={data} axes={axes} />
                    </div>
                  </div>
                </Col>
              </Row> */}
              {/* graph end here */}
              <Row className="mt-4 ms-2">
              <h4>{t("pending_req")}</h4>
                <Col md={12}>
                  <div className="audit-request-box">
                    <Table responsive="sm" className="UserListTable">
                      <thead>
                        <tr>
                          <th>{t("sr_no")}</th>
                          <th>{t("full_name")}</th>
                          <th>{t("email_address")}</th>
                          <th>{t("phone")}</th>
                          <th>{t("user_type")}</th>
                          <th>{t("status")}</th>
                          <th>{t("action")}</th>
                        </tr>
                      </thead>
                      <tbody>
                      {userData && userData.map((data, index) => {


                    return (<tr key={index}>
                      <td>{index + 1}</td>
                      <td>{data?.name ? data?.name :  data.firstName? data.firstName+" "+data.lastName:"-"}</td>
                      <td>{data.email}</td>
                      <td>{data?.phone ? data?.phone : "-"}</td>
                      <td>{ data.role=="ROLE_DRIVER" ? t("driver") : t("cultivator")}</td>
                     
                      {/* <td><p className="address-text">{data?.address ? data?.address : "-"}</p></td> */}

                      <td>
                      <div className="status-btns">
                        {data.status === "Pending" && data.role === "ROLE_CULTIVATOR" ? (
                          <Link to={"/cultivator-details/" + data.id} className="delivered waiting">
                            <i className="fa-solid fa-clock"></i> {t("pending")}
                          </Link>
                        ) : data.status === "Pending" && data.role === "ROLE_DRIVER" ? (
                          <Link to={"/driver-details/" + data.id} className="delivered waiting">
                            <i className="fa-solid fa-clock"></i> {t("pending")}
                          </Link>
                        ) : (
                          <>
                            {data.status === "Accept" ? (
                              <Link className="delivered">
                                <i className="fa-solid fa-check"></i> {t("accepted")}
                              </Link>
                            ) : data.status === "Reject" ? (
                              <Link className="delivered rejected">
                                <i className="fa-solid fa-close"></i> {t("rejected")}
                              </Link>
                            ) : (
                              <Link className="delivered waiting">{t("incomplete_profile")}</Link>
                            )}
                          </>
                        )}
                      </div>

                        {/* {data?.reason ? <span className="show-reason" onClick={() => handleShowReason(data?.reason)}>View Rejection Reason</span> : ""} */}
                      </td>
                      <td>
                        <div className="butto-area">

                          <div className="action-boxes">
                            <OverlayTrigger placement="top" overlay={tooltip}>
                      {data.role=="ROLE_DRIVER"?
                             <Link to={"/driver-details/" + data.id}>
                                        <i class="fa-solid fa-eye"></i>
                                      </Link> :
                              <Link to={"/cultivator-details/" + data.id}>
                                <i class="fa-solid fa-eye"></i>
                              </Link>}
                            </OverlayTrigger>
                        
                            
                           
                          </div>
                        </div>
                      </td>
                    </tr>
                    )
                    })}
{/* No data found images add here start */}
                  {userData.length == 0 &&
                  <tr>
                  <td colSpan={7}>
                    <div className="text-center">
                      <div className="no-data-found-section">
                        <img src={require("../../assets/images/no-data.svg").default} alt="" />
                        <h5><b>No data found</b></h5>
                      </div>
                    </div>
                  </td>
                  </tr>}
                   
                      
                       
                      
                      
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
