import { Button, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { apiService } from "../../service/api.service";
import { useState, useEffect } from "react";
import { ThreeDotSpinner } from "../loader";
import swal from "sweetalert";
import { all } from "axios";
import { useTranslation } from 'react-i18next';
import '../../i18n';
export const Settings = () => {
  const { t } = useTranslation();
  const [loader, setLoader] = useState(false);
  const [allFields, setallFields] = useState({
    DELIVERY_FEE_FLAT: "",
    DELIVERY_FEE_FLAT_ID: 0,
    DELIVERY_FEE_PER_KM: "",
    DELIVERY_FEE_PER_KM_ID: 0,
    CULTIVATOR_SERVICE_FEE_IN_PERCENT: "",
    CULTIVATOR_SERVICE_FEE_IN_PERCENT_ID: 0,
    CUSTOMER_SERVICE_FEE_IN_PERCENT: "",
    CUSTOMER_SERVICE_FEE_IN_PERCENT_ID: 0,
    DRIVER_SERVICE_FEE_IN_PERCENT: "",
    DRIVER_SERVICE_FEE_IN_PERCENT_ID: 0,
    SERVICE_AREA_IN_KM: "",
    SERVICE_AREA_IN_KM_ID: 0,
    DRIVER_RESPONSE_TIME: "",
    DRIVER_RESPONSE_TIME_ID: 0,
    CULTIVATOR_RESPONSE_TIME:"",
    CULTIVATOR_RESPONSE_TIME_ID: 0,
    SERVICE_PROVIDERS_PAYOUT_AFTER_DAYS:"",
    SERVICE_PROVIDERS_PAYOUT_AFTER_DAYS_ID:1
  });
  const [allFieldsEdit, setallFieldsEdit] = useState({
    DELIVERY_FEE_FLAT: false,
    DELIVERY_FEE_PER_KM: false,
    CULTIVATOR_SERVICE_FEE_IN_PERCENT: false,
   CUSTOMER_SERVICE_FEE_IN_PERCENT: false,
   DRIVER_SERVICE_FEE_IN_PERCENT: false,
    SERVICE_AREA_IN_KM: false,
    DRIVER_RESPONSE_TIME:false,
    SERVICE_PROVIDERS_PAYOUT_AFTER_DAYS:false,
    CULTIVATOR_RESPONSE_TIME:false
   
  });

  const setOneTrue = (fieldName) => {
  
    const filteredArray = allFields.list.filter(item => item.setting === fieldName);
    console.log("==list===",filteredArray,"=====")
    setsettingField(filteredArray[0])
    // setsettingField((prevObj) => {
    //   return {
    //     ...prevObj,
    //    filteredArray
    //   };
    // });
    setallFieldsEdit(prevFields => {
      const updatedFields = { ...prevFields };
      for (const key in updatedFields) {
        if (key === fieldName) {
          updatedFields[key] = true;
        } else {
          updatedFields[key] = false;
        }
      }
     return updatedFields;
    });
  };
  const handleKeyDownnumber = (event) => {
    if (event.which === 32) {
      event.preventDefault(); // Prevent space key action
    }
    if (event.key === "Enter" || event.which === 13) {
      event.preventDefault(); // Prevent the default behavior (page refresh)
     }
  };
  const tooltip = <Tooltip id="tooltip">{t("save")}</Tooltip>;
  const tooltip_edit = <Tooltip id="tooltip">{t("edit")}</Tooltip>;
  const [settingField, setsettingField] = useState({
    value: "",
    setting: "",
  });

  useEffect(() => {
    SettingGet();
  }, []);
  const setValues = (idVal, index, value) => {
    
    let IndexNameId = index + "_ID".toString();
    console.log(idVal, index, value);
    console.log(IndexNameId);
    setallFields((prevObj) => {
      return {
        ...prevObj,
        [IndexNameId]: idVal,
        [index]: value,
      };
    });

    // allFields.index= value
    console.log("======allFields=====",allFields);
  };

  const SettingAdd = async (settingValue) => {
    try {
      if (
        typeof settingField.value !== "number" &&
        settingField.value.trim() == "" &&
        settingField.setting == settingValue
      ) {
        swal(
          "Error",
          "You can't set a value empty either set 0 or give some value.",
          "error"
        ).then(() => {});
        return;
      }
  
      // Assign the field value if it's empty
      if (
        typeof settingField.value !== "number" &&
        settingField.value.trim() == ""
      ) {
        let IndexNameId = settingValue + "_ID".toString();
        setsettingField((prevObj) => ({
          ...prevObj,
          value: allFields[`${settingValue}`],
          setting: `${settingValue}`,
          id: allFields[`${IndexNameId}`],
        }));
      }
  
      let fieldValue = parseFloat(settingField.value);
  
      // Validation for DELIVERY_FEE_FLAT and DELIVERY_FEE_PER_KM (Must not exceed 1000000)
      if (
        (settingValue === "DELIVERY_FEE_FLAT" ||
          settingValue === "DELIVERY_FEE_PER_KM") &&
        fieldValue > 100000000
      ) {
        swal("Error", "Value can't be greater than 100000000", "error");
        return;
      }
  
      // Validation for all other fields (Must not exceed 100)
      if (
        settingValue !== "DELIVERY_FEE_FLAT" &&
        settingValue !== "DELIVERY_FEE_PER_KM" &&
        fieldValue > 100
      ) {
        swal("Error", "Value can't be greater than 100", "error");
        return;
      }
  
      // Existing validation for SERVICE_PROVIDERS_PAYOUT_AFTER_DAYS
      if (settingValue == "SERVICE_PROVIDERS_PAYOUT_AFTER_DAYS") {
        if (fieldValue > 10) {
          swal("Error", "Value can't be more than 10", "error");
          return;
        }
        if (fieldValue == 0) {
          swal("Error", "Value should be minimum 1", "error");
          return;
        }
      }
  
      var response = await apiService.settingUpdate(settingField);
      if (response.status == 200 || response.status == 201) {
        setLoader(false);
        swal(t("success"), t("data_updated"), "success").then(() => {
          window.location.reload("/");
        });
      }
    } catch (error) {
      setLoader(false);
  
      if (error?.response?.status == 401) {
        swal({ text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear();
          window.location.href = "/";
        });
      } else {
        swal({
          icon: "error",
          text: error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message,
          button: "OK",
        }).then(() => {
          localStorage.clear();
          window.location.href = "/";
        });
      }
    }
  };
  
  const SettingGet = async () => {
    try {
      var response;
      response = await apiService.settingGet();
      if (response.status == 200 || response.status == 201) {
        setallFields(response.data.data)
        response.data.data.list.map((data, index) => {
          console.log("=====>>>>data====",data)
          setValues(data.id, data.setting, data.value);
          
        });
        setLoader(false);
      }
    } catch (error) {
      console.log("====error===>>>>>", error);

      setLoader(false);
      if (error?.response?.status == 401) {
        swal({ text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear();
          window.location.href = "/";
        });
      }
    }
  };
  return (
    <>
      <div className="dashboard-main-area">
        <div className="dashboard-area">
          <Row className="justify-content-center">
            <Col md={11}>
              <div className="heading-top-area mb-3">
                <div>
                  <h2 className="main-heading mt-0">{t("settings")} </h2>
                </div>
              </div>
              <Row>
                <Col md={6}>
                  <div className="setting_boxes">
                    <h5 className="mb-3">{t("service_fee")}:</h5>
                    <div className="categories-add-box">
                      <div className="setting_inner_box">
                        <p className="mb-0">
                          <b>{t("customer_fee")}</b>
                        </p>
                        <div className="d-flex align-items-center">
                          <input
                            onKeyDown={handleKeyDownnumber}
                            maxLength={3}
                            onWheel={() => document.activeElement.blur()}
                            type="number"
                            disabled={!allFieldsEdit.CUSTOMER_SERVICE_FEE_IN_PERCENT}
                            value={allFields.CUSTOMER_SERVICE_FEE_IN_PERCENT}
                            className="form-control w-25"
                            onChange={(e) => {
                              setsettingField((prevObj) => {
                                return {
                                  ...prevObj,
                                  id: allFields.CUSTOMER_SERVICE_FEE_IN_PERCENT_ID,
                                  value: e.target.value,
                                  setting: "CUSTOMER_SERVICE_FEE_IN_PERCENT",
                                };
                              });
                              setallFields((prevObj) => {
                                return {
                                  ...prevObj,
                                  CUSTOMER_SERVICE_FEE_IN_PERCENT:
                                    e.target.value,
                                };
                              });
                            }}
                          />
                          <span className="ms-3">
                            <b>%</b>
                          </span>
                        </div>
                        {!allFieldsEdit.CUSTOMER_SERVICE_FEE_IN_PERCENT?
                        <OverlayTrigger placement="top" overlay={tooltip_edit}>
                          <Button
                            className="save-btn"
                            onClick={() =>
                              
                              setOneTrue("CUSTOMER_SERVICE_FEE_IN_PERCENT")
                            }
                          >
                            <i className="fa-solid fa-edit text-light"></i>
                          </Button>
                        </OverlayTrigger>:
                        <OverlayTrigger placement="top" overlay={tooltip}>
                          <Button
                            className="save-btn"
                            onClick={() =>
                              SettingAdd("CUSTOMER_SERVICE_FEE_IN_PERCENT")
                            }
                          >
                            <i className="fa-solid fa-floppy-disk"></i>
                          </Button>
                        </OverlayTrigger> }
                      </div>
                      <div className="setting_inner_box">
                        <p className="mb-0">
                          <b>{t("driver_fee")} </b>
                        </p>
                        
                        <div className="d-flex align-items-center">
                          <input
                            type="number"
                            disabled={!allFieldsEdit.DRIVER_SERVICE_FEE_IN_PERCENT}
                            value={allFields.DRIVER_SERVICE_FEE_IN_PERCENT}
                            className="form-control w-25"
                            onChange={(e) => {
                              setsettingField((prevObj) => {
                                return {
                                  ...prevObj,
                                  id: allFields.DRIVER_SERVICE_FEE_IN_PERCENT_ID,
                                  value: e.target.value,
                                  setting: "DRIVER_SERVICE_FEE_IN_PERCENT",
                                };
                              });
                              setallFields((prevObj) => {
                                return {
                                  ...prevObj,
                                  DRIVER_SERVICE_FEE_IN_PERCENT: e.target.value,
                                };
                              });
                            }}
                          />
                          <span className="ms-3">
                            <b>%</b>
                          </span>
                        </div>
                        {!allFieldsEdit.DRIVER_SERVICE_FEE_IN_PERCENT?
                        <OverlayTrigger placement="top" overlay={tooltip_edit}>
                          <Button
                            className="save-btn"
                            onClick={() =>
                              setOneTrue("DRIVER_SERVICE_FEE_IN_PERCENT")
                            }
                          >
                            <i className="fa-solid fa-edit text-light"></i>
                          </Button>
                        </OverlayTrigger>:
                        <OverlayTrigger placement="top" overlay={tooltip}>
                          <Button
                            className="save-btn"
                            onClick={() =>
                              SettingAdd("DRIVER_SERVICE_FEE_IN_PERCENT")
                            }
                          >
                            <i className="fa-solid fa-floppy-disk"></i>
                          </Button>
                        </OverlayTrigger>}
                      </div>
                      <div className="setting_inner_box">
                        <p className="mb-0">
                          <b>{t("cultivator_fee")}</b>
                        </p>
                        <div className="d-flex align-items-center">
                          <input
                            onKeyDown={handleKeyDownnumber}
                            disabled={!allFieldsEdit.CULTIVATOR_SERVICE_FEE_IN_PERCENT}
                            maxLength={3}
                            onWheel={() => document.activeElement.blur()}
                            type="number"
                            value={allFields.CULTIVATOR_SERVICE_FEE_IN_PERCENT}
                            className="form-control w-25"
                            onChange={(e) => {
                              setsettingField((prevObj) => {
                                return {
                                  ...prevObj,
                                  id: allFields.CULTIVATOR_SERVICE_FEE_IN_PERCENT_ID,
                                  value: e.target.value,
                                  setting: "CULTIVATOR_SERVICE_FEE_IN_PERCENT",
                                };
                              });
                              setallFields((prevObj) => {
                                return {
                                  ...prevObj,
                                  CULTIVATOR_SERVICE_FEE_IN_PERCENT:
                                    e.target.value,
                                };
                              });
                            }}
                          />
                          <span className="ms-3">
                            <b>%</b>
                          </span>
                        </div>
                        {!allFieldsEdit.CULTIVATOR_SERVICE_FEE_IN_PERCENT?
                        <OverlayTrigger placement="top" overlay={tooltip_edit}>
                          <Button
                            className="save-btn"
                            onClick={() =>
                              setOneTrue("CULTIVATOR_SERVICE_FEE_IN_PERCENT")
                            }
                          >
                            <i className="fa-solid fa-edit text-light"></i>
                          </Button>
                        </OverlayTrigger>:
                        <OverlayTrigger placement="top" overlay={tooltip}>
                          <Button
                            className="save-btn"
                            onClick={() =>
                              SettingAdd("CULTIVATOR_SERVICE_FEE_IN_PERCENT")
                            }
                          >
                            <i className="fa-solid fa-floppy-disk"></i>
                          </Button>
                        </OverlayTrigger>}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="setting_boxes">
                    <h5 className="mb-3">{t("delivery_fee")}:</h5>
                    <div className="categories-add-box">
                      <div className="setting_inner_box">
                        <p className="mb-0">
                          <b>{t("flat_amt")}</b>
                          <span className="ms-3">
                          (<b>$</b>)
                          </span>
                        </p>

                        <div className="d-flex align-items-center">
                          <input
                            onKeyDown={handleKeyDownnumber}
                            maxLength={3}
                            disabled={!allFieldsEdit.DELIVERY_FEE_FLAT}
                            onWheel={() => document.activeElement.blur()}
                            type="number"
                            className="form-control w-25"
                            value={allFields.DELIVERY_FEE_FLAT}
                            onChange={(e) => {
                              setsettingField((prevObj) => {
                                return {
                                  ...prevObj,
                                  id: allFields.DELIVERY_FEE_FLAT_ID,
                                  value: e.target.value,
                                  setting: "DELIVERY_FEE_FLAT",
                                };
                              });
                              setallFields((prevObj) => {
                                return {
                                  ...prevObj,
                                  DELIVERY_FEE_FLAT: e.target.value,
                                };
                              });
                            }}
                          />
                          <span className="ms-3">
                            <b></b>
                          </span>
                        </div>
                        {!allFieldsEdit.DELIVERY_FEE_FLAT?
                        <OverlayTrigger placement="top" overlay={tooltip_edit}>
                          <Button
                            className="save-btn"
                            onClick={() =>
                              setOneTrue("DELIVERY_FEE_FLAT")
                            }
                          >
                            <i className="fa-solid fa-edit text-light"></i>
                          </Button>
                        </OverlayTrigger>:
                        <OverlayTrigger placement="top" overlay={tooltip}>
                          <Button
                            className="save-btn"
                            onClick={() => SettingAdd("DELIVERY_FEE_FLAT")}
                          >
                            <i className="fa-solid fa-floppy-disk"></i>
                          </Button>
                        </OverlayTrigger>}
                      </div>
                      <div className="setting_inner_box">
                        <p className="mb-0">
                          <b>{t("amt_km")} </b>
                          <span className="ms-3">
                          <b>($)</b>
                          </span>
                        </p>
                        <div className="d-flex align-items-center">
                          <input
                            onKeyDown={handleKeyDownnumber}
                            disabled={!allFieldsEdit.DELIVERY_FEE_PER_KM}
                            maxLength={3}
                            onWheel={() => document.activeElement.blur()}
                            type="number"
                            className="form-control w-25"
                            value={allFields.DELIVERY_FEE_PER_KM}
                            onChange={(e) => {
                              setsettingField((prevObj) => {
                                return {
                                  ...prevObj,
                                  id: allFields.DELIVERY_FEE_PER_KM_ID,
                                  value: e.target.value,
                                  setting: "DELIVERY_FEE_PER_KM",
                                };
                              });
                              setallFields((prevObj) => {
                                return {
                                  ...prevObj,
                                  DELIVERY_FEE_PER_KM: e.target.value,
                                };
                              });
                            }}
                          />
                          <span className="ms-3">
                            <b></b>
                          </span>
                        </div>
                        {!allFieldsEdit.DELIVERY_FEE_PER_KM?
                        <OverlayTrigger placement="top" overlay={tooltip_edit}>
                          <Button
                            className="save-btn"
                            onClick={() =>
                              setOneTrue("DELIVERY_FEE_PER_KM")
                            }
                          >
                            <i className="fa-solid fa-edit text-light"></i>
                          </Button>
                        </OverlayTrigger>:
                        <OverlayTrigger placement="top" overlay={tooltip}>
                          <Button
                            className="save-btn"
                            onClick={() => SettingAdd("DELIVERY_FEE_PER_KM")}
                          >
                            <i className="fa-solid fa-floppy-disk"></i>
                          </Button>
                        </OverlayTrigger>}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className="mt-4">
                <Col md={6}>
                  <div className="setting_boxes">
                    <h5 className="mb-3">{t("service_area")}:</h5>
                    <div className="categories-add-box">
                      <div className="setting_inner_box">
                        <div className="d-flex align-items-center">
                          <input
                            onKeyDown={handleKeyDownnumber}
                            disabled={!allFieldsEdit.SERVICE_AREA_IN_KM}
                            maxLength={3}
                            onWheel={() => document.activeElement.blur()}
                            type="number"
                            className="form-control w-25"
                            value={allFields.SERVICE_AREA_IN_KM}
                            onChange={(e) => {
                              setsettingField((prevObj) => {
                                return {
                                  ...prevObj,
                                  id: allFields.SERVICE_AREA_IN_KM_ID,
                                  value: e.target.value,
                                  setting: "SERVICE_AREA_IN_KM",
                                };
                              });
                              setallFields((prevObj) => {
                                return {
                                  ...prevObj,
                                  SERVICE_AREA_IN_KM: e.target.value,
                                };
                              });
                            }}
                          />
                          <span className="ms-3">
                            <b>{t("km")}</b>
                          </span>
                        </div>
                        {!allFieldsEdit.SERVICE_AREA_IN_KM?
                        <OverlayTrigger placement="top" overlay={tooltip_edit}>
                          <Button
                            className="save-btn"
                            onClick={() =>
                              setOneTrue("SERVICE_AREA_IN_KM")
                            }
                          >
                            <i className="fa-solid fa-edit text-light"></i>
                          </Button>
                        </OverlayTrigger>:
                        <OverlayTrigger placement="top" overlay={tooltip}>
                          <Button
                            className="save-btn"
                            onClick={() => SettingAdd("SERVICE_AREA_IN_KM")}
                          >
                            <i className="fa-solid fa-floppy-disk"></i>
                          </Button>
                        </OverlayTrigger>}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="setting_boxes">
                    <h5 className="mb-3">{t("time")}:</h5>
                    <div className="categories-add-box">
                      <div className="setting_inner_box">
                        <div className="d-flex align-items-center">
                          <input
                          disabled={!allFieldsEdit.DRIVER_RESPONSE_TIME}
                            onKeyDown={handleKeyDownnumber}
                            maxLength={3}
                            onWheel={() => document.activeElement.blur()}
                            type="number"
                            value={allFields.DRIVER_RESPONSE_TIME}
                            className="form-control w-25"
                            onChange={(e) => {
                              setsettingField((prevObj) => {
                                return {
                                  ...prevObj,
                                  id: allFields.DRIVER_RESPONSE_TIME_ID,
                                  value: e.target.value,
                                  setting: "DRIVER_RESPONSE_TIME",
                                };
                              });
                              setallFields((prevObj) => {
                                return {
                                  ...prevObj,
                                  DRIVER_RESPONSE_TIME: e.target.value,
                                };
                              });
                            }}
                          />
                          <span className="ms-3">
                            <b>{t("minute")}</b>
                          </span>
                        </div>
                        {!allFieldsEdit.DRIVER_RESPONSE_TIME?
                        <OverlayTrigger placement="top" overlay={tooltip_edit}>
                          <Button
                            className="save-btn"
                            onClick={() =>
                              setOneTrue("DRIVER_RESPONSE_TIME")
                            }
                          >
                            <i className="fa-solid fa-edit text-light"></i>
                          </Button>
                        </OverlayTrigger>:
                        <OverlayTrigger placement="top" overlay={tooltip}>
                          <Button
                            className="save-btn"
                            onClick={() => SettingAdd("DRIVER_RESPONSE_TIME")}
                          >
                            <i className="fa-solid fa-floppy-disk"></i>
                          </Button>
                        </OverlayTrigger>}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="setting_boxes">
                    <h5 className="mb-3">{t("service")} :</h5>
                    <div className="categories-add-box">
                      <div className="setting_inner_box">
                        <div className="d-flex align-items-center">
                          <input
                          disabled={!allFieldsEdit.SERVICE_PROVIDERS_PAYOUT_AFTER_DAYS}
                            onKeyDown={handleKeyDownnumber}
                            maxLength={3}
                            onWheel={() => document.activeElement.blur()}
                            type="number"
                            value={allFields.SERVICE_PROVIDERS_PAYOUT_AFTER_DAYS}
                            className="form-control w-25"
                            onChange={(e) => {
                            
                              setsettingField((prevObj) => {
                                return {
                                  ...prevObj,
                                  id: allFields.SERVICE_PROVIDERS_PAYOUT_AFTER_DAYS_ID,
                                  value: e.target.value,
                                  setting: "SERVICE_PROVIDERS_PAYOUT_AFTER_DAYS",
                                };
                              });
                              setallFields((prevObj) => {
                                return {
                                  ...prevObj,
                                  SERVICE_PROVIDERS_PAYOUT_AFTER_DAYS: e.target.value,
                                };
                              });
                            }}
                          />
                          <span className="ms-3">
                            <b>{t("day")} </b>
                          </span>
                        </div>
                        {!allFieldsEdit.SERVICE_PROVIDERS_PAYOUT_AFTER_DAYS?
                        <OverlayTrigger placement="top" overlay={tooltip_edit}>
                          <Button
                            className="save-btn"
                            onClick={() =>
                              setOneTrue("SERVICE_PROVIDERS_PAYOUT_AFTER_DAYS")
                            }
                          >
                            <i className="fa-solid fa-edit text-light"></i>
                          </Button>
                        </OverlayTrigger>:
                        <OverlayTrigger placement="top" overlay={tooltip}>
                          <Button
                            className="save-btn"
                            onClick={() => SettingAdd("SERVICE_PROVIDERS_PAYOUT_AFTER_DAYS")}
                          >
                            <i className="fa-solid fa-floppy-disk"></i>
                          </Button>
                        </OverlayTrigger>}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="setting_boxes">
                    <h5 className="mb-3">{t("time_cultivator")}:</h5>
                    <div className="categories-add-box">
                      <div className="setting_inner_box">
                        <div className="d-flex align-items-center">
                          <input
                          disabled={!allFieldsEdit.CULTIVATOR_RESPONSE_TIME}
                            onKeyDown={handleKeyDownnumber}
                            maxLength={3}
                            onWheel={() => document.activeElement.blur()}
                            type="number"
                            value={allFields.CULTIVATOR_RESPONSE_TIME}
                            className="form-control w-25"
                            onChange={(e) => {
                              setsettingField((prevObj) => {
                                return {
                                  ...prevObj,
                                  id: allFields.CULTIVATOR_RESPONSE_TIME_ID,
                                  value: e.target.value,
                                  setting: "CULTIVATOR_RESPONSE_TIME",
                                };
                              });
                              setallFields((prevObj) => {
                                return {
                                  ...prevObj,
                                  CULTIVATOR_RESPONSE_TIME: e.target.value,
                                };
                              });
                            }}
                          />
                          <span className="ms-3">
                            <b>{t("minute")}</b>
                          </span>
                        </div>
                     {!allFieldsEdit.CULTIVATOR_RESPONSE_TIME?
                        <OverlayTrigger placement="top" overlay={tooltip_edit}>
                          <Button
                            className="save-btn"
                            onClick={() =>
                              setOneTrue("CULTIVATOR_RESPONSE_TIME")
                            }
                          >
                            <i className="fa-solid fa-edit text-light"></i>
                          </Button>
                        </OverlayTrigger>:
                        <OverlayTrigger placement="top" overlay={tooltip}>
                          <Button
                            className="save-btn"
                            onClick={() => SettingAdd("CULTIVATOR_RESPONSE_TIME")}
                          >
                            <i className="fa-solid fa-floppy-disk"></i>
                          </Button>
                        </OverlayTrigger>}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
