import React, { useState } from "react";
import OtpInput from 'react-otp-input';
import { Button, Form } from "react-bootstrap";
import { apiService } from "../../service/api.service";
import swal from "sweetalert";
import { useTranslation } from 'react-i18next';
import '../../i18n';

const OtpVerification = () => {
    const [otp, setOtp] = useState("");
    const [otpErr, setOtpErr] = useState("");
    const { t } = useTranslation();
    const admin2FAPhone = localStorage.getItem("phone")

    const handleVerify = () => {
        if (otp.length !== 4) {
            setOtpErr("Please enter a valid 4-digit OTP.");
            return;
        }
        setOtpErr("");
        verifyOtp();
    };

    const verifyOtp = async() => {
        try {

            const data={
              "phone": admin2FAPhone,
              "otp":otp,
              "role":"admin"
            }
           
            let response = await apiService.verifyOtp(data);
            if ((response?.status == 200 || response?.status==201) && (response?.data)) {
              let responseData = response?.data.data.token
              localStorage.setItem("admin_token",responseData);
              localStorage.setItem("login", true)
             
              swal(t("success"),t("login_success"), "success").then((value)=>{
                if(value){
                  window.location.href = "/Home"
                }
              })
              
            }
          } catch (error) {
            if(error?.response?.status == 400){
              
              swal({icon:"error", text: error?.response?.data?.message, button: "OK", }).then((value) => {
                console.log('OK button clicked after error alert')
              })
            }else{
              swal({icon:"error", text: error?.response?.data?.message ? error?.response?.data?.message: error?.message, button: "OK", }).then((value) => {
                console.log('OK button clicked after error alert')
              })
            }
            
          }
    }

    return (
        <div className="otp-verification">
        <div className="text-center">
            <h3 className="otp-text">Enter OTP</h3>
            <Form className="otp-form">
                <Form.Label>Enter the OTP sent to your phone ending with ({admin2FAPhone.slice(-4)})</Form.Label>
                <div className="otp-box">
                    <OtpInput
                        className="form-control"
                        value={otp}
                        onChange={setOtp}
                        numInputs={4}
                        renderSeparator={<span> </span>}
                        renderInput={(props) => <input {...props} />}
                    />
                </div>
                <span className="errors text-danger">{otpErr}</span>
                <div>
                  <Button className="green-btn mt-3" onClick={handleVerify}>Verify</Button>
                </div>
                
            </Form>
        </div>
        </div>
    );
};

export default OtpVerification;